<script lang="ts" setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { faChevronLeft, faClose } from "@fortawesome/free-solid-svg-icons";

const date = ref(new Date());
const now = new Date();
const currentTime = parseInt(
  now.getHours() +
    "" +
    ("0" + now.getMinutes()).substr(-2) +
    "" +
    ("0" + now.getSeconds()).substr(-2)
);
// If it's evening
if (currentTime > 180000) {
  // Set default date to tomorrow
  date.value = new Date(now.getTime() + 24 * 60 * 60 * 1000);
}

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
});

const urlDate = new Date()?.toISOString()?.substring(0, 10);
const { data: dates_src } = await useBookingData(
  `/api/v1/book_dates/${props.state.branch.id}`,
  {
    query: {
      service_ids: props.state?.service?.map((i) => i.id),
      staff_id: props.state?.barber?.id,
      date_from: urlDate,
      date_to: "9999-01-01",
    },
  }
  // "/api/v1/booking/locations/${props.state.branch.id}/search/staff", //?datetime=2024-06-26T18:00:00&service_ids[]=10543082
);

const {
  data: times_src,
  refresh: times_refresh,
  clear: times_clear,
  status: times_status,
} = await useBookingData(
  () =>
    `/api/v1/book_times/${props.state.branch.id}/${
      props.state?.barber?.id || 0
    }/${date.value.toISOString().substring(0, 10)}/`,
  {
    query: {
      service_ids: props.state?.service?.map((i) => i.id),
    },
  }
  // "/api/v1/booking/locations/${props.state.branch.id}/search/staff", //?datetime=2024-06-26T18:00:00&service_ids[]=10543082
);

watch(date, (newVal) => {
  times_clear();
  times_refresh();
});
</script>

<template>
  <div class="BookingForm__page BookingForm__page3">
    <div class="BookingForm__headerBar">
      <!-- <span @click="$emit('goBack')">
        <font-awesome-icon
          :icon="faChevronLeft"
          :style="{ color: '#fff', width: '12px' }"
        />
        Zpět</span
      > -->
      <span></span>
      <span @click="$emit('goHome')" style="font-size: 18px">
        <font-awesome-icon
          :icon="faClose"
          :style="{ color: '#fff', width: '18px' }"
      /></span>
    </div>
    <strong class="BookingForm__title link">Vyberte datum:</strong>
    <VueDatePicker
      v-model="date"
      inline
      auto-apply
      :min-date="new Date()"
      :max-date="
        dates_src?.data?.booking_dates[dates_src.data?.booking_dates.length - 1]
      "
      :allowed-dates="dates_src?.data?.booking_dates"
      prevent-min-max-navigation
      locale="cs-CZ"
      month-name-format="long"
      dark
      expanded
      :action-row="{
        showSelect: false,
        showCancel: false,
        showNow: false,
        showPreview: false,
      }"
      :enable-time-picker="false"
      :month-change-on-scroll="false"
    >
    </VueDatePicker>
    <strong class="BookingForm__title link" style="margin-top: 24px"
      >Vyberte si čas:</strong
    >
    <div v-if="times_status == 'pending'">
      <div class="loader"></div>
    </div>
    <div v-else-if="times_src?.data?.length" class="TimeSelect__wrap">
      <div
        v-for="item in times_src?.data"
        class="TimeSelect"
        @click="$emit('dateSelect', item)"
      >
        {{ item.time }}
      </div>
    </div>
    <div v-else class="p">
      V daný den nejsou dostupné žádné termíny, vyberte si prosím jiné datum.
    </div>
  </div>
</template>

<style>
.BookingForm__title {
  display: block;
  margin-bottom: 18px;
}

.dp__month_year_select,
.dp__month_year_select:hover {
  pointer-events: none;
}

.dp__theme_dark {
  display: block;
  font-family: inherit !important;
  --dp-background-color: transparent;
  --dp-text-color: #fff;
  --dp-hover-color: #484848;
  --dp-hover-text-color: #fff;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #005cb2;
  --dp-primary-disabled-color: #61a8ea;
  --dp-primary-text-color: #fff;
  --dp-secondary-color: #a9a9a9;
  --dp-border-color: transparent;
  --dp-menu-border-color: transparent;
  --dp-border-color-hover: #aaaeb7;
  --dp-border-color-focus: #aaaeb7;
  --dp-disabled-color: #737373;
  --dp-disabled-color-text: #d0d0d0;
  --dp-scroll-bar-background: #212121;
  --dp-scroll-bar-color: #484848;
  --dp-success-color: #00701a;
  --dp-success-color-disabled: #428f59;
  --dp-icon-color: #959595;
  --dp-danger-color: #e53935;
  --dp-marker-color: #e53935;
  --dp-tooltip-color: #3e3e3e;
  --dp-highlight-color: rgb(0 92 178 / 20%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #484848);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #fff);
  --dp-range-between-border-color: var(--dp-hover-color, #fff);
  --dp-menu-padding: 0;
}
.dp--header-wrap {
  padding: 4px 12px;
}
.dp__action_row {
  display: none;
}
.dp__cell_inner {
  margin: 0 auto;
}
.dp__calendar_header {
  text-transform: capitalize;
}
.dp__inner_nav_disabled {
  visibility: hidden;
}
/* .dp__menu_inner {
  padding: 0;
} */
.TimeSelect__wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: stretch;
}
.TimeSelect {
  padding: 8px;
  font-size: 16px;
  display: inline-block;
  border: 1px solid white;
  cursor: pointer;
  border-radius: 6px;
  flex-grow: 1;
  text-align: center;
  max-width: calc(20% - 7px);
  flex-basis: calc(20% - 7px);
}
</style>
