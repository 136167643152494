<template>
  <button class="BookingForm__inputWrap">
    <div class="BookingForm__inputIcon" v-if="$props.icon">
      <font-awesome-icon
        :icon="getIcon(icon)"
        :style="{ color: '#fff', width: '16px' }"
      />
    </div>
    <input
      class="BookingForm__inputText"
      :value="text"
      :disabled="inputDisabled"
      :required="inputRequired"
      @input="(e) => $emit('input', e)"
    />
    <div class="BookingForm__inputArrow" v-if="$props.rightIcon">
      <font-awesome-icon
        :icon="getIcon($props.rightIcon)"
        :style="{ color: '#fff', width: '16px' }"
      />
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  faUsers,
  faCalendar,
  faScissors,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

function getIcon(icon_name: string) {
  switch (icon_name) {
    case "chevron-right":
      return faChevronRight;
    case "users":
      return faUsers;
    case "calendar":
      return faCalendar;
    case "scissors":
      return faScissors;
  }
}

export type IconsType = "users" | "calendar" | "scissors";

export default defineComponent({
  setup() {
    return { getIcon };
  },
  props: {
    icon: {
      type: String as PropType<IconsType>,
      validator: (prop: IconsType) =>
        ["users", "calendar", "scissors"].includes(prop),
      required: false,
    },
    rightIcon: {
      type: String,
      default: "chevron-right",
      required: false,
    },
    text: {
      type: String,
      default: "",
      required: true,
    },
    inputDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    inputRequired: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
});
</script>

<style scoped>
.BookingForm__inputWrap {
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 48px;
  border-radius: 6px;
  border: 1px solid var(--frame, #fff);
  background: transparent;
  align-items: center;
  padding: 12px 16px;
  color: #fff;
  box-sizing: border-box;
  gap: 12px;
  &:hover .BookingForm__inputArrow {
    transform: translateX(3px);
  }
}
.BookingForm__inputIcon {
  width: 20px;
}
.BookingForm__inputText {
  flex-grow: 2;
  text-align: left;
  appearance: none;
  background: transparent;
  outline: 0;
  border: 0;
  &[disabled] {
    pointer-events: none;
  }
}
.BookingForm__inputArrow {
  transition: transform 0.15s ease;
}
</style>
