<template>
  <button
    class="BookingForm__branchesItem"
    title="Sledujte naše sociální sítě."
    :class="{
      'BookingForm__branchesItem--active': active == true,
      'BookingForm__branchesItem--disabled': disabled == true,
    }"
  >
    <div
      class="BookingForm__branchesItemBackground"
      :style="`background-image: url('${img}')`"
    />
    <span class="BookingForm__branchesItemTitle">{{ title }}</span>
    <span class="BookingForm__branchesItemSubtitle">{{ subtitle }}</span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {};
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
});
</script>

<style scoped>
.BookingForm__branchesItem {
  width: 100%;
  cursor: pointer;
  display: block;
  appearance: none;
  height: 178px;
  border: none;
  position: relative;
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  overflow: hidden;
  padding: 0;
  /* &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
  } */
  /* &:hover {
    .BookingForm__branchesItemBackground {
      opacity: 0.5;
    }
  } */
  /* &.BookingForm__branchesItem--active {
    text-decoration: underline;
    &::after {
      box-shadow: inset 0 0 0 3px var(--color-primary);
    }
    .BookingForm__branchesItemBackground {
      opacity: 0.7;
      transform: scale(1.05);
    }
  } */
  &--disabled {
    pointer-events: none;
    .BookingForm__branchesItemBackground {
      opacity: 0.2;
    }
    .BookingForm__branchesItemTitle,
    .BookingForm__branchesItemSubtitle {
      opacity: 0.3;
    }
    &::after {
      content: "Dočasně \a MIMO PROVOZ";
      white-space: pre;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      font-family: var(--display-font);
      text-shadow: var(--color-primary-dark) 0 0 10px;
      transform: rotate(-9deg);
    }
  }
}
.BookingForm__branchesItemBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.4;
  background-size: 100%;
  transition: all 0.3s ease;
}
.BookingForm__branchesItemTitle {
  display: block;
  position: relative;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
}
.BookingForm__branchesItemSubtitle {
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
</style>
