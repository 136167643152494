<script lang="ts" setup>
// import { VueMarqueeSlider } from "vue3-marquee-slider";
// import "@/node_modules/vue3-marquee-slider/dist/style.css";
// import { ref, getCurrentInstance } from "vue";

// const instance = getCurrentInstance();
// const uuid = ref(instance?.uid);
const rnd = ref(Math.floor(Math.random() * (30 - 10 + 1) + 10) / 10);
const duration = ref(30 / rnd.value);
</script>

<template>
  <div class="SectionMarquee__wrap">
    <div
      class="SectionMarquee__item1 title color-primary"
      aria-hidden
      :style="{
        'animation-duration': `${duration}s`,
      }"
    >
      <slot />&nbsp;<slot />&nbsp;<slot />&nbsp;<slot />&nbsp;
    </div>
    <div
      class="SectionMarquee__item2 title color-primary"
      aria-hidden
      :style="{ 'animation-duration': `${duration}s` }"
    >
      <slot />&nbsp;<slot />&nbsp;<slot />&nbsp;<slot />&nbsp;
    </div>
  </div>
</template>

<style scoped>
.SectionMarquee__wrap {
  display: flex;
  text-wrap: nowrap;
  overflow: hidden;
}
.SectionMarquee__item1 {
  animation: marquee 6s linear infinite;
}
.SectionMarquee__item2 {
  animation: marquee 6s linear infinite;
}

@keyframes marquee {
  from {
    /* pushes the sun down past the viewport */
    transform: translateX(0);
  }
  to {
    /* returns the sun to its default position */
    transform: translateX(-100%);
  }
}
</style>
