<template>
  <div class="ReviewRating">
    <font-awesome-icon
      v-for="i in Math.trunc(props.rating)"
      :icon="faStar"
      class="ReviewRating__star ReviewRating__star--full"
      :style="{ color: '#f6bb06', width: '15px' }"
    />
    <font-awesome-icon
      v-if="props.rating % 1 != 0"
      :icon="faStarHalfStroke"
      class="ReviewRating__star ReviewRating__star--half"
      :style="{ color: '#f6bb06', width: '15px' }"
    />
    <font-awesome-icon
      v-for="i in 5 - Math.ceil(props.rating)"
      :icon="['far', 'star']"
      class="ReviewRating__star ReviewRating__star--empty"
      :style="{ color: '#f6bb06', width: '15px' }"
    />
  </div>
</template>

<script lang="ts" setup>
import { faStar, faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";

const props = defineProps({
  rating: {
    type: Number,
    default: 0,
  },
});
</script>

<style scoped></style>
