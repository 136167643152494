<template>
  <form
    class="BookingForm__page BookingPageReview"
    @submit.prevent="submitForm"
  >
    <div class="BookingForm__headerBar">
      <!-- <span @click="$emit('goBack')">
        <font-awesome-icon
          :icon="faChevronLeft"
          :style="{ color: '#fff', width: '12px' }"
        />
        Zpět</span
      > -->
      <span></span>
      <span @click="$emit('goHome')" style="font-size: 18px">
        <font-awesome-icon
          :icon="faClose"
          :style="{ color: '#fff', width: '18px' }"
      /></span>
    </div>
    <!-- <strong class="BookingForm__title link" @click="$emit('goToHomeTab')"
      >Kliknutím vyberte barbera</strong
    > -->
    <!-- <pre>{{ props.state }}</pre> -->
    <label class="BookingPageReview__label link"> Vybraná pobočka:</label>
    <div class="BookingPageReview__summaryRow">
      <p>
        <font-awesome-icon
          class="BookingPageReview__labelIcon"
          :icon="faHouse"
          :style="{ color: '#fff', width: '12px' }"
        />
        {{
          `${state.branch?.attributes.title} - ${state.branch?.attributes.address}`
        }}
      </p>
    </div>

    <label class="BookingPageReview__label link"> Váš barber:</label>
    <div class="BookingPageReview__summaryRow">
      <p>
        <font-awesome-icon
          class="BookingPageReview__labelIcon"
          :icon="faUsers"
          :style="{ color: '#fff', width: '12px' }"
        />
        {{ state.barber?.name }}
      </p>
    </div>

    <label class="BookingPageReview__label link"> Vybraný čas:</label>
    <div class="BookingPageReview__summaryRow">
      <p>
        <font-awesome-icon
          class="BookingPageReview__labelIcon"
          :icon="faCalendar"
          :style="{ color: '#fff', width: '12px' }"
        />
        {{
          new Date(state.date?.datetime).toLocaleString("cs-CZ", {
            dateStyle: "long",
            timeStyle: "short",
          })
        }}
      </p>
    </div>

    <label class="BookingPageReview__label link" for=""> Vybrané služby:</label>
    <div class="BookingPageReview__summaryRow">
      <p style="white-space: pre-line">
        <font-awesome-icon
          class="BookingPageReview__labelIcon"
          :icon="faScissors"
          :style="{ color: '#fff', width: '12px' }"
        />
        {{ state.service.map((i) => i.title).join(", \n") }}
      </p>
    </div>

    <div class="BookingPageReview__totalPrice link">
      Celkem:
      <span>{{ state.service.reduce((a, b) => a + b.price_min, 0) }}Kč</span>
    </div>

    <span class="BookingPageReview__hr">doplňte</span>
    <label
      class="BookingPageReview__label BookingPageReview__label--required link"
      :for="`${uid}-booking-name`"
      >Vaše jméno:</label
    >
    <input
      :id="`${uid}-booking-name`"
      inputmode="text"
      :class="{
        'BookingPageReview__input--error': !namesValid,
        BookingPageReview__input: true,
      }"
      @input="(e) => $emit('input-name', e)"
      autocomplete="name"
      v-model="validateData.name"
      @focusout="
        () => {
          validateVue.name.$touch();
          validateName();
        }
      "
    />
    <p v-show="!namesValid" class="BookingPageReview__errorText">
      Vyplňte prosím vaše jméno.
    </p>

    <label
      class="BookingPageReview__label BookingPageReview__label--required link"
      :for="`${uid}-booking-tel`"
      >Vaše telefonní číslo:</label
    >
    <div
      :class="{
        'BookingPageReview__rowInput--error': !phonesValid,
        BookingPageReview__rowInput: true,
      }"
    >
      <!-- Select for phone codes -->
      <!-- <input
        class="BookingPageReview__input BookingPageReview__input--phoneSelect"
        :class="{
          'BookingPageReview__input--error': !phonesValid,
        }"
        list="phoneCodesList"
        v-model="validateData.phone_code"
        @change="
          (e) => {
            // Validate phone number
          }
        "
      >
        <datalist id="phoneCodesList">
          <option v-for="item in phoneCodes" :value="item.code">
          {{ `(${item.country}) ${item.code}` }}
        </option>
        </datalist>
      </input> -->
      <multiselect
        class="BookingPageReview__input BookingPageReview__input--phoneSelect"
        :class="{
          'multiselect--error': !phonesValid,
        }"
        v-model="phoneCode"
        :options="phoneCodes"
        label="code"
        :placeholder="phoneCode.code"
        selectLabel=""
        selectGroupLabel=""
        selectedLabel=""
        deselectLabel=""
        openDirection="bottom"
        :searchable="true"
        :currentOptionLabel="phoneCode.code"
        :allowEmpty="false"
        :closeOnSelect="true"
        group-values="libs"
        group-label="language"
        :group-select="false"
        :customLabel="
          ({ country, code }) => {
            return `${country} - ${code} - ${regionNames.of(country)}`;
          }
        "
        @select="
          () => {
            if (props.state.client_tel) {
              validatePhone();
            }
          }
        "
      >
        <template #singleLabel> {{ phoneCode.code }} </template>
        <template #option="props">
          <!-- Group label option -->
          <span
            class="BookingPageReview__multiselectGroup"
            v-if="props.option.$isLabel"
          >
            <span>
              {{ props.option.$groupLabel }}
            </span>
          </span>
          <!-- Normal option -->
          <span
            :class="{
              BookingPageReview__multiselectOption: true,
              'BookingPageReview__multiselectOption--selected':
                props.option.country === phoneCode.country,
            }"
            v-if="!props.option.$isLabel"
          >
            <span class="BookingPageReview__multiselectOptionCode">
              {{ props.option.code }}
            </span>
            <span class="BookingPageReview__multiselectOptionCountry">
              {{
                "of" in regionNames
                  ? regionNames.of(props.option.country)
                  : props.option.country
              }}
            </span>
          </span>
        </template>
      </multiselect>

      <input
        class="BookingPageReview__input BookingPageReview__input--phoneInput"
        :class="{
          'BookingPageReview__input--error': !phonesValid,
        }"
        inputmode="tel"
        @input="
          (e) => {
            $emit('input-tel', e);
          }
        "
        @focusout="
          (e) => {
            phonesValid = isValidPhoneNumber(
              e.target?.value ? `${phoneCode.code}${e.target?.value}` : ''
            );
          }
        "
        maxlength="15"
        type="tel"
        autocomplete="tel"
        style="margin: 0"
        :id="`${uid}-booking-tel`"
      />
    </div>
    <p v-show="!phonesValid" class="BookingPageReview__errorText">
      Vyplňte prosím číslo ve správném formátu, např.
      {{ getExampleNumber(phoneCode.country, examples)?.nationalNumber }}
    </p>
    <label
      class="BookingPageReview__label BookingPageReview__label--required link"
      :for="`${uid}-booking-mail`"
      >Váš e-mail:</label
    >
    <input
      v-model="validateData.email"
      @input="
        (e) => {
          $emit('input-email', e);
        }
      "
      @focusout="
        (e) => {
          $emit('input-email', e);
          validateVue.email.$touch();
          validateEmail();
        }
      "
      :id="`${uid}-booking-mail`"
      :class="{
        'BookingPageReview__input--error': !emailsValid,
        BookingPageReview__input: true,
      }"
      inputmode="email"
      type="email"
      autocomplete="email"
    />
    <p v-show="!emailsValid" class="BookingPageReview__errorText">
      Email není ve správném formátu.
    </p>

    <label
      class="BookingPageReview__label link"
      :for="`${uid}-booking-comment`"
      style="margin-bottom: 8px"
      >Chcete nám zanechat vkaz?
    </label>
    <p class="p" style="margin-bottom: 12px">
      Máte slevový kód nebo nějaké zvláštní přání?
    </p>
    <input
      inputmode="text"
      class="BookingPageReview__input"
      @input="(e) => $emit('input-comment', e)"
      style="margin-bottom: 24px"
      :id="`${uid}-booking-comment`"
    />
    <label class="BookingPageReview__label link" :for="`${uid}-booking-notif`"
      >E-mailová Notifikace</label
    >
    <select
      class="BookingPageReview__input"
      @input="(e) => $emit('input-notif', e)"
      :value="props.state.client_notif"
      name=""
      :id="`${uid}-booking-notif`"
      style="margin-bottom: 24px"
    >
      <option value="" style="color: black">Neposílat upozornění</option>
      <option selected :value="1" style="color: black">
        1 Hodinu před začátkem
      </option>
      <option :value="2" style="color: black">2 Hodiny před začátkem</option>
      <option :value="3" style="color: black">3 Hodiny před začátkem</option>
      <option :value="4" style="color: black">4 Hodiny před začátkem</option>
      <option :value="5" style="color: black">5 Hodin před začátkem</option>
      <option :value="6" style="color: black">6 Hodin před začátkem</option>
      <option :value="7" style="color: black">7 Hodin před začátkem</option>
      <option :value="8" style="color: black">8 Hodin před začátkem</option>
      <option :value="9" style="color: black">9 Hodin před začátkem</option>
      <option :value="10" style="color: black">10 Hodin před začátkem</option>
      <option :value="11" style="color: black">11 Hodin před začátkem</option>
      <option :value="12" style="color: black">12 Hodin před začátkem</option>
    </select>

    <label class="" for="agreements" style="display: flex; margin-bottom: 24px"
      ><div
        :class="{
          'BookingPageReview__input--errorCheckbox': !consentValid,
        }"
        style="width: 20px; height: 20px"
      >
        <input
          @input="(e) => $emit('input-agreement', e)"
          type="checkbox"
          id="agreements"
          style="width: 16px; height: 16px; margin: 2px; flex-shrink: 0"
          v-model="validateData.consent"
          @change="
            () => {
              validateConsent();
            }
          "
        />
      </div>
      <p class="p" style="margin-left: 5px">
        Souhlasím se zpracováním svých osobních údajů a potvrzuji, že jsem si
        přečetl/a a přijal/a
        <a
          href="https://alteg.io/en/info/privacy"
          target="_blank"
          style="text-decoration: underline"
        >
          Zásady ochrany osobních údajů</a
        >
        a
        <a
          href="https://alteg.io/en/info/terms"
          target="_blank"
          style="text-decoration: underline"
        >
          Uživatelskou smlouvu</a
        >
      </p>
    </label>

    <p
      :class="{
        BookingPageReview__errorText: true,
        'BookingPageReview__errorText--hidden': consentValid,
        'BookingPageReview__errorText--consentText': true,
      }"
    >
      Pro odeslání objednávky musíte souhlasit se zpracováním osobních údajů.
    </p>

    <button
      class="BookingForm__submitButton"
      :disabled="req_status === 'pending'"
    >
      Potvrdit objednávku
    </button>
  </form>
</template>

<script lang="ts" setup>
import Multiselect from "vue-multiselect";
// Icons
import {
  faChevronLeft,
  faClose,
  faHouse,
  faUsers,
  faCalendar,
  faScissors,
} from "@fortawesome/free-solid-svg-icons";

import { required, email, minLength, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  isValidPhoneNumber,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  parsePhoneNumber,
  parseDigits,
} from "libphonenumber-js/min";
import type { CountryCode } from "libphonenumber-js/min";
import examples from "libphonenumber-js/mobile/examples";

/**
 * CONFIG
 */
//#region

// Which languages are to be in first group
const CODE_PREFERENCES = ["CZ", "SK", "PL"];

// Name for our groups in phone code multiselect
const FIRST_GROUPS_NAME_IN_PHONE_CODES = "Nejpoužívanější předvolby";
const SECOND_GROUPS_NAME_IN_PHONE_CODES = "Ostatní předvolby";

//#endregion

let regionNames = new Intl.DisplayNames(["cs-CZ"], { type: "region" });

// Object that stores data from inputs and is run through our validation rules in vuelidate
const validateData = ref({
  email: "",
  name: "",
  consent: false,
});

// Contains rules necessary for validation trough vuelidate
const rules = computed(() => {
  return {
    email: { required, email, minLength: minLength(1) },
    name: { required, minLength: minLength(1) },
    consent: { required, minLength: minLength(1) },
  };
});

// Storing selected country and its phone code
// Used to get proper phone number and then validate it
const phoneCode = ref<{ country: CountryCode; code: string }>({
  country: "CZ",
  code: "+420",
});

// Applying rules for validation
const validateVue = useVuelidate(rules, validateData);

// Storing all phone codes and their countries
// Used for multiselect options
const phoneCodes = computed(() => {
  let arr = [];
  let countries = getCountries();

  for (let i = 0; i < countries.length; i++) {
    arr.push({
      country: countries[i],
      code: `+${getCountryCallingCode(countries[i])}`,
    });
  }

  arr.sort();

  let newObj = {};
  let newArr = [];

  for (let i = 0; i < CODE_PREFERENCES.length; i++) {
    const str = CODE_PREFERENCES[i];

    newObj = {
      country: CODE_PREFERENCES[i],
      code: `+${getCountryCallingCode(str)}`,
    };
    const index = arr.findIndex((x) => x.country == CODE_PREFERENCES[i]);
    arr.splice(index, 1);
    newArr.push(newObj);
  }

  //newArr.push(arr);

  //return newArr.flat();
  return [
    {
      language: FIRST_GROUPS_NAME_IN_PHONE_CODES,
      libs: newArr,
    },
    {
      language: SECOND_GROUPS_NAME_IN_PHONE_CODES,
      libs: arr,
    },
  ];
});

// Storing if input values are valid
const namesValid = ref(true);
const phonesValid = ref(true);
const emailsValid = ref(true);
const consentValid = ref(true);

const emit = defineEmits([
  "goBack",
  "goHome",
  "goToHomeTab",
  "input-name",
  "input-tel",
  "input-email",
  "input-comment",
  "input-notif",
  "input-agreement",
  "success",
]);
const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
});

const { uid } = getCurrentInstance();

const utm = useNuxtUTM();

let req_status = ref();

async function submitForm() {
  if (req_status.value === "pending" || !validateForm()) {
    return;
  }

  const data = {
    phone:
      "+" + parseDigits(`${phoneCode.value.code}${props.state.client_tel}`),
    fullname: props.state.client_name,
    email: String(props.state.client_mail).toLowerCase(),
    // code: "38829", // SMS CONFIRMATION
    comment: props.state.client_comment,
    // type: "mobile",
    // notify_by_sms: null,
    notify_by_email: +props.state.client_notif || undefined,
    api_id: "sharksbarber-web",
    referrer: new URLSearchParams(utm.value[0]?.utmParams).toString(),
    appointments: [
      {
        id: 1,
        services: props.state.service.map((i) => i.id),
        staff_id: props.state.barber?.id,
        datetime: props.state.date?.datetime,
      },
    ],
  };

  // console.log(`/api/v1/book_record/${props.state.branch.id}`, data);
  // emit("success");
  const { status } = await useBookingData(
    `/api/v1/book_record/${props.state.branch.id}`,
    // "/api/v1/booking/locations/738728/search/staff", //?datetime=2024-06-26T18:00:00&service_ids[]=10543082
    {
      method: "POST",
      body: {
        ...data,
      },
      onResponse({ request, response, options }) {
        if (response._data?.success === true) {
          emit("success", response);
        } else if (response._data?.meta?.message) {
          alert(response._data?.meta?.message);
        } else {
          throw new Error(
            "[BookingForm] Submit form failed unexpectedly, res: " +
              JSON.stringify(response._data)
          );
        }
      },
    }
  );
  req_status = status;
}

/**
 * Validation functions
 *
 */
//#region

// Validates all necessary values from form
// Returns bool if form is valid
function validateForm() {
  // Checking for errors in necessary forms input values
  validateEmail();
  validatePhone();
  validateName();
  validateConsent();

  // Checking if every necessary value from form is valid
  if (
    emailsValid.value &&
    namesValid.value &&
    phonesValid.value &&
    consentValid.value
  ) {
    return true;
  }

  return false;
}

function validateEmail() {
  validateData.value.email = String(props.state.client_mail).toLowerCase();
  emailsValid.value =
    !validateVue.value.email.$error && validateData.value.email != "";
}

function validatePhone() {
  phonesValid.value = isValidPhoneNumber(
    props.state.client_tel
      ? `${phoneCode.value.code}${props.state.client_tel}`
      : ""
  );
}

function validateName() {
  namesValid.value = !validateVue.value.name.$error && validateData.value.name != "";
}

function validateConsent() {
  consentValid.value = validateData.value.consent;
}
//#endregion
</script>

<style>
.BookingPageReview {
}
.BookingPageReview__label {
  display: flex;
  align-items: center;
  margin: 0 0 12px;
  cursor: default;
  pointer-events: none;
  &[for] {
    cursor: pointer;
    pointer-events: auto;
  }
  &--required {
    &::after {
      content: "*";
      padding-left: 6px;
      color: darkred;
    }
  }
}

.BookingPageReview__summaryRow {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  p {
    display: flex;
    justify-content: flex-start;
    line-height: 1.3;
  }
}
.BookingPageReview__summaryEdit {
  font-size: 0.7em;
  opacity: 0.7;
  cursor: pointer;
}
.BookingPageReview__labelIcon {
  margin: 2px 8px 0 0;
}
.BookingPageReview__input {
  background: transparent;
  width: 100%;
  height: 48px;
  border-radius: 6px;
  border: 1px solid var(--frame, #fff);
  padding: 12px 16px;
  margin-bottom: 24px;
  height: 48px;
}

.BookingPageReview__input--error {
  border: 1px solid darkred;

  margin-bottom: 6px;
}

.BookingPageReview__input--errorCheckbox {
  border-color: darkred;
  background-color: darkred;
}

.BookingPageReview__hr {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 26px 0 30px;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    border-top: 1px #fff solid;
  }
  &::before {
    margin-right: 20px;
  }
  &::after {
    margin-left: 20px;
  }
}
.BookingPageReview__totalPrice {
  display: flex;
  justify-content: space-between;
  margin: 34px 0 26px;
}

.BookingPageReview__errorText {
  color: darkred;
  margin-bottom: 16px !important;
}

.BookingPageReview__errorText--hidden {
  display: none;
}

.BookingPageReview__errorText--consentText {
  margin-left: 26px;
  padding-bottom: 12px;
}
</style>

<style>
.BookingPageReview__rowInput {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  height: 48px;
}

.BookingPageReview__rowInput--error {
  margin-bottom: 6px;
}

.BookingPageReview__input--phoneInput {
  position: relative;
  flex-grow: 3;
  border-radius: 0 6px 6px 0;
  height: 100%;
  z-index: 8;
  height: 48px;
}

.BookingPageReview__input--phoneSelect {
  flex-grow: 1;
  border-right: none;
  border-radius: 6px 0 0 6px;
  max-width: 90px;
  min-width: 40%;
  height: 100%;
  display: flex;
  margin-bottom: 24px;
  height: 48px;

  option {
    background-color: var(--color-primary-dark);
  }

  datalist {
    width: 100%;
  }

  @media screen(sm) {
    min-width: 30%;
  }
}

.BookingPageReview__multiselectOption--selected {
  font-weight: bold;
}

.multiselect,
.multiselect__tags,
.multiselect__content-wrapper,
.multiselect__single {
  overflow: visible;
  border: none;
  color: var(--color-white);
  width: 100%;
  padding: 0px;
  background-color: var(--color-primary-dark);
  box-sizing: border-box;

  input {
    color: var(--color-white);
    background-color: var(--color-primary-dark);
  }
}

.multiselect__tags {
  z-index: 7;
  border-radius: 6px 0 0 6px !important;

  input {
    border-radius: 6px !important;
    padding: 13px 16px 11px 12px;
    z-index: 12;
  }
}

.multiselect {
  border: 1px solid var(--color-white);
  border-radius: 6px 0 0 6px !important;
  border-right: none;
  margin: 0;
  z-index: 7;
  height: 100%;
}

.multiselect--error {
  border-color: darkred;
}

.multiselect__select {
  z-index: 10;
  height: 46px !important;
  top: 0px;
  right: 0px;
}

.multiselect__single {
  padding: 13px 16px 12px 12px;
}

.multiselect__content {
  overflow-y: scroll !important;

  height: 300px;
  scrollbar-width: thin;

  li {
    background-color: var(--color-primary-dark);
    border-bottom: 1px solid var(--color-white);
    padding: 2px 8px;

    text-wrap: nowrap;
  }
}

.multiselect__content::-webkit-scrollbar,
.multiselect__content::-webkit-scrollbar-button,
.multiselect__content::-webkit-scrollbar:horizontal,
.multiselect__content::-webkit-scrollbar-thumb,
.multiselect__content::-webkit-scrollbar-track,
.multiselect__content::-webkit-scrollbar-track-piece,
.multiselect__content::-webkit-scrollbar:vertical,
.multiselect__content::-webkit-scrollbar-corner,
.multiselect__content::-webkit-resizer {
  display: unset !important;
  scrollbar-width: thin;
  scrollbar-color: grey white;
}

.multiselect__content-wrapper {
  overflow: hidden;
  width: 400px;
  position: absolute;
  top: 49px;
  left: -1px;
  z-index: 8;
  border: 1px solid var(--color-white);
  border-radius: 6px;
}

.multiselect__option {
  width: 100%;
}

.multiselect__element[role="option"]:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.multiselect__option--highlight {
  background-color: transparent;
  text-wrap: nowrap;

  span {
  }
}

.multiselect__option--group {
  background-color: var(--color-primary-dark) !important;
  color: white;
  font-weight: bold;
}

.multiselect__option--disabled {
  color: white !important;
}

.multiselect__option--highlight:hover {
}

.multiselect__option--selected,
.multiselect__option--selected:hover {
  background-color: var(--color-primary-dark) !important;
  text-wrap: nowrap;

  span {
  }
}

.BookingPageReview__multiselectOption {
}

.BookingPageReview__multiselectOptionCode {
  display: inline-block;
  padding-right: 15px;
  color: white;
  text-align: center;
  width: 20%;
}

.BookingPageReview__multiselectOptionCountry {
}

.BookingPageReview__multiselectGroup {
}
</style>
