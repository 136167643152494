<script lang="ts" setup></script>

<template>
  <div class="SectionHeroText color-primary h1">
    <slot />
  </div>
</template>

<style scoped>
.SectionHeroText {
  max-width: 840px;
  margin: 0 auto;
}
</style>
