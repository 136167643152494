<template>
  <div>
    <span v-if="!toggled" class="ExpandableText__button" @click="toggled = true"
      >Zobrazit více</span
    >
    <p
      class="p"
      :class="{
        'ExpandableText--hidden': !toggled,
      }"
    >
      {{ text }}
    </p>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});

const toggled = ref(false);
</script>

<style>
.ExpandableText--hidden {
  margin-top: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ExpandableText__button {
  float: right;
  text-decoration: underline;
  padding: 0 0 0 8px;
  cursor: pointer;
}
</style>
