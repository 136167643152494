export const useBookingData: typeof useFetch = (request, opts?) => {
  const config = useRuntimeConfig();

  return useLazyFetch(request, {
    baseURL: config.public.baseURL,
    headers: {
      Authorization:
        "Bearer jxjmeewgz94z2yf6ffcc, User 55a04b2d6f18f85945158deaaf4fb5a5",
      Accept: "application/vnd.api.v2+json",
    },
    server: false,
    ...opts,
  });
};
