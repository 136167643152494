<template>
  <div class="BookingForm__page">
    <h2 class="h2">Rezervace proběhla úspěšně!</h2>
    <p class="h4" style="line-height: 1.4; margin: 26px 0 38px">
      Těšíme se na vás
      <strong class="link">
        {{
          new Date(state.date?.datetime).toLocaleString("cs-CZ", {
            dateStyle: "long",
            timeStyle: "short",
          })
        }}
      </strong>
      na adrese
      <strong class="link">{{ state.branch?.attributes.address }}</strong
      >.
    </p>
    <!-- <button class="BookingForm__submitButton">Přidat do kalendáře</button> -->
    <!-- <add-to-calendar-button
      name="Shark's Barber"
      description=""
      :startDate="new Date(state.date.datetime).toISOString().split('T')[0]"
      :startTime="state.date.time"
      endTime="17:45"
      timeZone="Europe/Prague"
      :location="state.branch"
      organizer="Shark's Barbers|info@sharksbarber.cz"
      availability="busy"
      options="'Apple','Google','iCal','Outlook.com','Yahoo'"
      listStyle="dropdown-static"
      hideBackground
    ></add-to-calendar-button> -->
    <!-- <ul class="p">
      <li>
        <strong>Vybraná pobočka:</strong><br />
        {{
          `${state.branch?.attributes.title} - ${state.branch?.attributes.address}`
        }}
      </li>
      <li>
        <strong>Váš barber:</strong><br />
        {{ state.barber?.name }}
      </li>
      <li>
        <strong>Vybraný čas:</strong><br />
        {{
          new Date(state.date?.datetime).toLocaleString("cs-CZ", {
            dateStyle: "long",
            timeStyle: "short",
          })
        }}
      </li>
      <li>
        <strong>Vybrané služby:</strong><br />
        {{ state.service.map((i) => i.title).join(", \n") }}
      </li>
      <li>
        <strong>Celkem:</strong><br />
        {{ state.service.reduce((a, b) => a + b.price_min, 0) }}Kč
      </li>
      <li>
        <strong>Jméno rezervace:</strong><br />
        {{ state.client_name }}
      </li>
      <li>
        <strong>Telefon rezervace:</strong><br />
        {{ state.client_tel }}
      </li>
      <li>
        <strong>Email rezervace:</strong><br />
        {{ state.client_mail }}
      </li>
      <li>
        <strong>SMS Notifikace:</strong><br />
        {{ state.client_notif }}
      </li>
    </ul> -->

    <!-- <pre>{{ data }}</pre>
    <pre>{{ $props.state }}</pre> -->
  </div>
</template>

<script lang="ts" setup>
// import "add-to-calendar-button";

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
});

const data = {
  phone: props.state.client_tel,
  fullname: props.state.client_name,
  email: props.state.client_mail,
  // code: "38829", // SMS CONFIRMATION
  comment: props.state.client_comment,
  // type: "mobile",
  notify_by_sms: props.state.client_notif,
  notify_by_email: 24,
  // api_id: "777",
  appointments: [
    {
      id: 1,
      services: [props.state.service.id],
      staff_id: props.state.barber?.id,
      datetime: props.state.date?.datetime,
    },
  ],
};
</script>

<style></style>
